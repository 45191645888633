import React from 'react';
import { bool, func, string } from 'prop-types';

import {
  Text,
  Box,
  Center,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerCloseButton,
  DrawerHeader,
  Heading,
  Image,
} from '@chakra-ui/react';

import BANK from '@invitato/helpers/dist/constants/banks';
import txtWording from './locales';

function ModalBank({ onClose, visible, onCopyText, lang }) {
  const renderBank = (bank, userName, bankNumber) => {
    return (
      <Box padding="16px 0" fontFamily="serif" textAlign="center">
        <Center>
          <Image src={bank.logo} marginBottom="8px" maxWidth="80px" />
        </Center>
        <Text color="mainColor" marginTop="0" fontWeight="bold">
          {bankNumber}
        </Text>
        <Text color="black">{userName}</Text>
        <Center>
          <Button
            size="xs"
            fontWeight="normal"
            colorScheme="blackAlpha"
            bgColor="mainColor"
            marginTop="16px"
            onClick={() => onCopyText(bankNumber)}
          >
            {txtWording.modalCopy[lang]}
          </Button>
        </Center>
      </Box>
    );
  };

  return (
    <Drawer size="full" onClose={onClose} isOpen={visible}>
      <DrawerOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <DrawerContent maxWidth="500px">
        <DrawerCloseButton marginTop="12px" />
        <DrawerHeader />
        <DrawerBody>
          <Heading
            textAlign="center"
            fontWeight="normal"
            fontSize="3xl"
            color="mainColor"
            fontFamily="cursive"
            marginTop="24px"
          >
            Bank Transfer
          </Heading>
          <Text align="center" fontFamily="normal" fontSize="md" margin="16px 0" color="black">
            {txtWording.modalDesc[lang]}
          </Text>
          {renderBank(BANK.bca, 'Winda Yunita', '0077778881')}
          {renderBank(BANK.bca, 'Robbi Salam', '6870861255')}
          {/* {renderBank(BANK.gopay, 'Groom & Bride', '0101010101')} */}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

ModalBank.propTypes = {
  lang: string,
  visible: bool.isRequired,
  onClose: func.isRequired,
  onCopyText: func.isRequired,
};

ModalBank.defaultProps = {
  lang: 'id',
};

export default ModalBank;
