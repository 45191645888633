import React, { useState } from 'react';
import { func, string } from 'prop-types';
import { BiArrowBack } from 'react-icons/bi';
import {
  Box,
  Heading,
  Text,
  FormControl,
  Input,
  Checkbox,
  FormLabel,
  Textarea,
  Button,
  Center,
  useToast,
  MenuButton,
  Menu,
  InputGroup,
  Icon,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import useGiftConfirmation from '@/usecase/use-gift-confirmation';
import { useGuest } from '@/context/guest';
import useFormFields from '@/hooks/useFormFields';
import useInvitation from '@/hooks/useInvitation';
import { COUNTRY_CODES } from '@constants/countryCodes';
import { BiChevronDown } from 'react-icons/bi';
import { BG_COVER } from '@/constants/assets';
import { ENABLE_SEND_GIFT, ENABLE_BANK_TRANSFER } from '@/constants/feature-flags';
import txtWording from './locales';

import useLang from '@/hooks/useLang';

const INPUT_COMMON_PROPS = {
  isRequired: true,
  size: 'md',
  type: 'text',
  variant: 'outline',
  colorScheme: 'blackAlpha',
  borderRadius: '0',
  backgroundColor: 'mainColorText',
  fontFamily: 'serif',
  color: 'whiteAlpha.800',
  _placeholder: { color: 'whiteAlpha.800' },
  _focus: { borderColor: 'mainColorText' },
};

const FORM_LABEL_PROPS = {
  fontSize: 'sm',
  color: 'mainColor',
  margin: '8px 0 0 0',
  fontFamily: 'serif',
};

export const SELECT_COMMON_PROPS = {
  borderRadius: '0',
  size: 'md',
  variant: 'outline',
  backgroundColor: 'mainColorText',
  color: 'mainColorTextLight',
  fontFamily: 'serif',
  fontSize: 'sm',
  _focus: { borderColor: 'mainColorText' },
  _hover: { backgroundColor: 'bgSecondary' },
  _active: { backgroundColor: 'bgSecondary' },
};

function Confirmation({ onBack }) {
  const [isBankTransfer, setIsBankTransfer] = useState(false);
  const [isGift, setIsGift] = useState(false);
  const toast = useToast();
  const lang = useLang();
  const isInvitation = useInvitation();

  const { onPostConfirmation, loading } = useGiftConfirmation();
  const { guest, isRegistered } = useGuest();
  const { name, phone_number, country_code } = guest;

  const { formFields, createChangeHandler, onResetForm } = useFormFields({
    name: isRegistered ? name : isInvitation ? '' : name,
    isGift: false,
    note: '',
    phone_number: phone_number || '',
  });
  const [selectedCountryCode, setSelectedCountryCode] = useState(country_code || '62');

  const handleCountryCodeChange = (code) => {
    setSelectedCountryCode(code);
  };
  /**
   * @function handleSendConfirmation
   * @description a function handler for submit confirmation
   */
  const handleSendConfirmation = async () => {
    if (!formFields.name) {
      toast({
        title: 'Oops!',
        description: txtWording.fillNameFirst[lang],
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    await onPostConfirmation({
      name: formFields.name,
      country_code: selectedCountryCode,
      phone_number: formFields.phone_number,
      notes: formFields.note,
      isBankTransfer: String(isBankTransfer).toUpperCase(),
      isGiftCard: String(isGift).toUpperCase(),
      onSuccess: () => {
        onResetForm();
        toast({
          title: txtWording.submitted[lang],
          description: txtWording.successSent[lang],
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: 'Oops!',
          description: txtWording.failedSent[lang],
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    });
  };

  return (
    <Box
      bgImage={`url(${BG_COVER})`}
      bgSize="cover"
      minHeight="100vh"
      bgPosition="center"
      padding="32px"
    >
      <Box bgColor="white" padding="24px 16px" borderRadius="16px" boxShadow="lg">
        {/* Heading & Desc Section */}
        <Box textAlign="center" color="mainColorText">
          <Heading fontWeight="normal" fontSize="4xl" fontFamily="cursive">
            {txtWording.title[lang]}
          </Heading>
          <Text fontSize="sm" padding="16px 0 " fontFamily="serif">
            {txtWording.desc[lang]}
          </Text>
        </Box>
        <Box>
          <FormControl margin="8px 0">
            <FormLabel {...FORM_LABEL_PROPS}>{txtWording.name[lang]}:</FormLabel>
            <Input
              {...INPUT_COMMON_PROPS}
              placeholder={txtWording.name[lang]}
              value={formFields.name}
              onChange={createChangeHandler('name')}
            />
          </FormControl>
          <FormControl margin="8px 0">
            <FormLabel {...FORM_LABEL_PROPS}>{txtWording.hp[lang]}:</FormLabel>
            <InputGroup size="sm">
              <Menu>
                <MenuButton
                  {...SELECT_COMMON_PROPS}
                  as={Button}
                  rightIcon={<Icon as={BiChevronDown} />}
                >
                  {`+${selectedCountryCode}`}
                </MenuButton>
                <MenuList maxHeight="200px" overflowY="scroll">
                  {COUNTRY_CODES.map((country) => (
                    <MenuItem
                      key={country.dial_code}
                      onClick={() => handleCountryCodeChange(country.dial_code)}
                    >
                      {`${country.name}: +${country.dial_code}`}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
              <Input
                {...INPUT_COMMON_PROPS}
                placeholder="..."
                type="number"
                value={formFields.phone_number}
                onChange={createChangeHandler('phone_number')}
              />
            </InputGroup>
          </FormControl>
          <FormControl margin="24px 0" fontFamily="serif">
            <FormLabel {...FORM_LABEL_PROPS}>{txtWording.giftType[lang]}:</FormLabel>
            {ENABLE_BANK_TRANSFER && (
              <Box>
                <Checkbox
                  value={isBankTransfer}
                  onChange={() => setIsBankTransfer(!isBankTransfer)}
                >
                  <Text color="mainColorText" fontSize="sm">
                    {txtWording.bank[lang]}
                  </Text>
                </Checkbox>
              </Box>
            )}
            {ENABLE_SEND_GIFT && (
              <Box>
                <Checkbox
                  value={isGift}
                  fontSize="sm"
                  onChange={() => setIsGift(!isGift)}
                  color="mainColorText"
                >
                  <Text color="mainColorText" fontSize="sm">
                    {txtWording.gift[lang]}
                  </Text>
                </Checkbox>
              </Box>
            )}
          </FormControl>
          <FormControl margin="8px 0">
            <FormLabel {...FORM_LABEL_PROPS}>{txtWording.notes[lang]}:</FormLabel>
            <Textarea
              {...INPUT_COMMON_PROPS}
              value={formFields.note}
              placeholder="..."
              onChange={createChangeHandler('note')}
            />
          </FormControl>
        </Box>
        <Box marginTop="24px">
          <Center>
            <Button
              isLoading={loading}
              fontWeight="normal"
              size="sm"
              colorScheme="blackAlpha"
              bgColor="mainColorText"
              onClick={handleSendConfirmation}
            >
              {txtWording.sendConfirm[lang]}
            </Button>
          </Center>
        </Box>
      </Box>
      <Box paddingTop="24px">
        <Center>
          <Button
            leftIcon={<BiArrowBack />}
            type="button"
            size="sm"
            fontWeight="normal"
            color="mainColorText"
            bgColor="bgPrimary"
            onClick={onBack}
          >
            {isInvitation ? (
              <>{txtWording.backToHome[lang]}</>
            ) : (
              <>{txtWording.backToAnnoncement[lang]}</>
            )}
          </Button>
        </Center>
      </Box>
    </Box>
  );
}

Confirmation.propTypes = {
  lang: string,
  onBack: func.isRequired,
};

Confirmation.defaultProps = {
  lang: 'id',
};

export default Confirmation;
