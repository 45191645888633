import React, { useState, useEffect } from 'react';

import { bool, string } from 'prop-types';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Center,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Spacer,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';
import ImgDivider from '@/assets/border/divider.png';
import Image from '@/components/Common/LazyImage';

import { DEFAULT_LANGUAGE as lang } from '@/constants/feature-flags';
import { useGuest } from '@/context/guest';
import useWishes from '@/usecase/use-wishes';
import useFormFields from '@hooks/useFormFields';
import useInvitation from '@hooks/useInvitation';
import txtWording from './locales';
import Wishlist from './WishlishCard';
import { INPUT_COMMON_PROPS, ALERT, FORM_LABEL_PROPS, ERROR_TYPE } from './types';
import BorderFrame from '@components/Common/BorderFrame';

function WishesSection() {
  const [showAlert, setShowAlert] = useState(ALERT);
  const [errorType, setErrorType] = useState(ERROR_TYPE);

  const { guest, isRegistered } = useGuest();
  const { code, name } = guest;
  const { isLoading, isMutating, data, error, onPostWish, onRefetch } = useWishes();
  const isInvitation = useInvitation();

  const { formFields, createChangeHandler, onResetForm } = useFormFields({
    name: isRegistered ? name : isInvitation ? '' : name,
    wish: '',
  });

  useEffect(() => {
    onResetForm();
  }, [isRegistered]);

  const toast = useToast();

  const handleSetAlert = (isSuccess) => {
    let messageTitle = txtWording.success[lang];
    let messageContent = txtWording.successMessage[lang];

    if (!isSuccess) {
      toast({ status: 'error', title: 'Oops!', description: txtWording.failedMessage[lang] });
    } else {
      toast({
        status: 'success',
        title: messageTitle,
        description: messageContent,
        isClosable: true,
      });
    }
  };

  /**
   * function to submit wishlist data
   * @param {FormEvent}
   * @returns {void}
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorType(ERROR_TYPE);

    if (!formFields.name || !formFields.wish) {
      setErrorType({
        name: !formFields.name && txtWording.requiredField[lang],
        wish: !formFields.wish && txtWording.requiredField[lang],
      });
      return;
    }

    await onPostWish({
      code,
      name: formFields.name,
      wish: formFields.wish,
      onError: () => {
        handleSetAlert(false);
      },
      onSuccess: () => {
        onResetForm();
        handleSetAlert(true);
        onRefetch();
      },
    });
  };

  const renderAlert = () => {
    if (!showAlert.messageTitle) return null;

    return (
      <Box paddingBottom="0" paddingTop="16px">
        <Alert status={showAlert.success ? 'success' : 'error'} borderRadius="lg">
          <AlertIcon />
          <AlertDescription fontSize="sm">{showAlert.messageContent}</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            size="sm"
            onClick={() => setShowAlert(ALERT)}
          />
        </Alert>
      </Box>
    );
  };

  return (
    <Box padding="24px 24px" bgColor="mainColor">
      <Box padding="8px 24px" width="100%" pos={'relative'}>
        <BorderFrame isHeader />
        {/* Title & Description sections */}
        <BorderFrame />
        <Box textAlign="center" color="secondaryColorText">
          <WithAnimation right>
            <Heading
              marginTop="32px"
              fontWeight="normal"
              fontSize="39px"
              fontFamily="cursive"
              dangerouslySetInnerHTML={{ __html: txtWording.title[lang] }}
            />
          </WithAnimation>
          <WithAnimation left>
            <Text
              margin="12px 0"
              fontFamily="serif"
              dangerouslySetInnerHTML={{ __html: txtWording.desc[lang] }}
            />
          </WithAnimation>
        </Box>
        {renderAlert()}
        {/* Box for FORM */}
        <WithAnimation>
          <Box paddingTop="2">
            <FormControl margin="8px 0" isInvalid={errorType.name}>
              <FormLabel {...FORM_LABEL_PROPS}>{txtWording.name[lang]}:</FormLabel>
              <Input
                {...INPUT_COMMON_PROPS}
                placeholder="..."
                onChange={createChangeHandler('name')}
                value={formFields.name}
              />
              <FormErrorMessage color="white" marginTop="4px">
                {errorType.name}{' '}
              </FormErrorMessage>
            </FormControl>
            <FormControl margin="16px 0" isInvalid={errorType.wish}>
              <FormLabel {...FORM_LABEL_PROPS}>{txtWording.wish[lang]}:</FormLabel>
              <Textarea
                {...INPUT_COMMON_PROPS}
                placeholder="..."
                onChange={createChangeHandler('wish')}
                value={formFields.wish}
              />

              <FormErrorMessage color="white" marginTop="4px">
                {errorType.wish}
              </FormErrorMessage>
            </FormControl>
            <Flex justifyItems="end">
              <Spacer />
              <Box>
                <Button
                  isLoading={isMutating}
                  size="sm"
                  padding="8px 24px"
                  bgColor="bgPrimary"
                  fontWeight="light"
                  color="mainColor"
                  borderRadius="2px"
                  onClick={handleSubmit}
                >
                  {txtWording.send[lang]}
                </Button>
              </Box>
            </Flex>
          </Box>
        </WithAnimation>
        <WithAnimation>
          <Center>
            <Image src={ImgDivider} maxWidth="100%" marginTop="24px" />
          </Center>
        </WithAnimation>
        {/* Wishlist Card */}
        {!error && (
          <WithAnimation>
            <Wishlist wishlistData={data} loading={isLoading} />
          </WithAnimation>
        )}
        <Box pos={'relative'}>
          <BorderFrame isFooter top="-36px" left="-24px" w="calc(100% + 48px)" h="44px" />
        </Box>
      </Box>
    </Box>
  );
}

WishesSection.propTypes = {
  lang: string,
  inverse: bool,
};

WishesSection.defaultProps = {
  lang: 'en',
  inverse: false,
};

export default React.memo(WishesSection);
