import React, { useState, useEffect } from 'react';
import { func } from 'prop-types';
import { Box, Center, Text } from '@chakra-ui/react';

import ScrollToDown from '@/components/Common/ScrollToDown/V1';
import WithAnimation from '@/components/Common/WithAnimation';
import useInvitation from '@/hooks/useInvitation';

import ImgLogo from '@/assets/icons/logo.png';
import BgBorder from '@/assets/border/cover.png';
import playMusic from '@/components/Common/FloatingMusic/utils/playMusic';
import Image from '@/components/Common/LazyImage';

import { THE_BRIDE } from '@/constants';
import { BG_COVER } from '@/constants/assets';
import { BG_SECONDARY } from '@/constants/colors';
import { DEFAULT_LANGUAGE as lang } from '@/constants/feature-flags';
import { useGuest } from '@/context/guest';
import txtWording from './locales';

/**
 * Function to render Component CoverV3
 * This component will be dissapear after user click show `Undangan`
 * @return {JSX.Element}
 */
function Cover({ onSeeDetail }) {
  const { isLoading } = useGuest();
  const [loading, setLoading] = useState(false);
  const [showTitle, setShowTitle] = useState(false);
  const [widthButton, setWidthButton] = useState('85px');
  const isInvitation = useInvitation();

  const handleLoadingComplete = () => {
    onSeeDetail();
    setLoading(false);
  };

  useEffect(() => {
    setShowTitle(true);
    if (!isInvitation && loading) {
      setTimeout(() => {
        handleLoadingComplete();
      }, 1500);
      return;
    }

    if (!isLoading && loading) {
      const timer = setTimeout(() => {
        handleLoadingComplete();
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [isLoading, loading, isInvitation]);

  const handleClickDetail = () => {
    if (loading) return;
    playMusic();
    setLoading(true);
  };

  useEffect(() => {
    setShowTitle(true);
    if (isInvitation) {
      setWidthButton('70px');
    }
  }, [isInvitation]);

  return (
    <Box minHeight="100vh" backgroundColor={BG_SECONDARY}>
      <Box
        minHeight="100vh"
        width="100%"
        bgSize="cover"
        bgPosition="center"
        bgImage={`url(${BG_COVER})`}
        padding="32px"
      >
        <WithAnimation>
          <Box
            bgImage={`url(${BgBorder})`}
            height="calc(100vh - 64px)"
            bgRepeat="no-repeat"
            width="100%"
            bgSize="100% 100%"
          >
            <Box display="block" paddingTop="2rem">
              {showTitle && (
                <Box>
                  <Box>
                    <WithAnimation left duration={2000}>
                      <Text
                        textAlign="center"
                        fontWeight="300"
                        textTransform="uppercase"
                        color="secondaryColorText"
                        fontSize="sm"
                      >
                        {isInvitation ? `The Wedding Of` : `Wedding Announcement`}
                      </Text>
                    </WithAnimation>
                    <WithAnimation right duration={2000}>
                      <Text
                        textAlign="center"
                        fontSize="3xl"
                        fontFamily="cursive"
                        color="secondaryColorText"
                      >
                        {THE_BRIDE}
                      </Text>
                    </WithAnimation>
                    <Center padding="0 0 8px 0" marginTop="-12px">
                      <WithAnimation duration={2000}>
                        <Image src={ImgLogo} maxWidth="130px" minHeight="100px" />
                      </WithAnimation>
                    </Center>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </WithAnimation>
      </Box>
      <Box width="100%" maxWidth="500px">
        <ScrollToDown
          text={isInvitation ? txtWording.open[lang] : txtWording.openAnnouncement[lang]}
          onClick={handleClickDetail}
          loading={loading}
          withIcon={false}
          buttonProps={{
            borderRadius: '4px',
            bgColor: 'white',
            colorScheme: 'whiteAlpha',
            color: 'black',
            fontWeight: '300',
            size: 'sm',
          }}
          style={{
            position: 'absolute',
            bottom: '7rem',
            left: `calc(50% - ${widthButton})`,
          }}
        />
      </Box>
      {showTitle && (
        <Box
          bottom="4rem"
          position="absolute"
          color="secondaryColorText"
          letterSpacing="1px"
          fontWeight="400"
          fontSize="10px"
          width="100%"
          maxW="500px"
        >
          <Box>
            <Text textAlign="center">Created with Love by Invitato</Text>
            <Text textAlign="center">{`© ${new Date().getFullYear()} ${THE_BRIDE}. All Rights Reserved`}</Text>
          </Box>
        </Box>
      )}
    </Box>
  );
}

Cover.propTypes = {
  onSeeDetail: func.isRequired,
};

export default Cover;
