import React from 'react';
import { bool, func } from 'prop-types';
import { BiArrowBack } from 'react-icons/bi';
import loadable from '@loadable/component';

import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  DrawerCloseButton,
  AspectRatio,
  Text,
  Image,
} from '@chakra-ui/react';

const LoadableGallerry = loadable(() =>
  import(/* webpackChunkName: "invitato-gallery-photos" */ './Gallery'),
);

import ImgLogo from '@/assets/icons/logo-invert.png';
import useInvitation from '@/hooks/useInvitation';
import Wave from '@/components/Common/Wave';

import { DEFAULT_LANGUAGE as lang, ENABLE_VIDEO_PREWEDDING } from '@/constants/feature-flags';
import { IMG_COVER_3 } from '@/constants/assets';
import { PREWEDDING_EMBED } from '@/constants';

import { photos } from './api/photo-data';

import txtWording from './locales';

function PhotoContent({ isOpen, onClose }) {
  const invitation = useInvitation();

  return (
    <Drawer size="full" isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent maxW="500px">
        <DrawerCloseButton color="transparent" />
        <DrawerBody bgColor="bgPrimary" width="100%" padding="0">
          <Box bgImage={`url(${IMG_COVER_3})`} height="450px" bgSize="cover" bgPosition="center" />
          <Wave isGallery width="100%" height="40px" transform="translateY(-38px)" />
          <Box height="38px" marginTop="-60px" />
          {/* Images Cover */}
          <Box
            padding="32px"
            paddingTop="32px"
            textAlign="center"
            color="mainColorText"
            fontFamily="serif"
          >
            <Text fontSize="xl">
              “And we will travel together <br />
              and just be in love forever.”
            </Text>
            {/* Prewedding Video */}
            {ENABLE_VIDEO_PREWEDDING && (
              <Box paddingTop="24px">
                <AspectRatio maxW="560px" ratio={16 / 9} borderRadius="lg">
                  <iframe
                    title="Prewedding"
                    src={PREWEDDING_EMBED}
                    allowFullScreen
                    loading="lazy"
                    style={{ borderRadius: '16px' }}
                  />
                </AspectRatio>
              </Box>
            )}
            {/* Jakarta Section */}
            <Box paddingTop="12px">
              <Box marginTop="24px">{isOpen && <LoadableGallerry data={photos} />}</Box>
            </Box>
          </Box>
          <Center>
            <Image src={ImgLogo} maxW="100px" marginTop="16px" />
          </Center>
          <Center padding="16px 0 48px 0">
            <Button
              leftIcon={<BiArrowBack />}
              size="sm"
              fontWeight="light"
              color="mainColor"
              variant="outline"
              colorScheme="blackAlpha"
              onClick={() => onClose()}
            >
              {invitation ? (
                <>{txtWording.backToInvitation[lang]}</>
              ) : (
                <>{txtWording.backToAnnouncement[lang]}</>
              )}
            </Button>
          </Center>
          <Box height="50px" />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

PhotoContent.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
};

export default PhotoContent;
