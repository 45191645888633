import React from 'react';
import { Box, AspectRatio, Heading, Text, Button, Center, Link } from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';

import { THE_BRIDE, YOUTUBE_EMBED, YOUTUBE_LINK } from '@/constants';
import { DEFAULT_LANGUAGE as lang } from '@/constants/feature-flags';
import { BG_LIVESTREAM } from '@/constants/assets';
import { BG_SECONDARY } from '@/constants/colors';

import { styles } from './styles';
import txtWording from './locales';

function YoutubeLiveSection({...rest}) {
  return (
    <Box zIndex="3" position="relative" bgColor="bgPrimary">
      <Box css={styles} height="100%">
        <Box
          width="100%"
          bgImage={`url(${BG_LIVESTREAM})`}
          minHeight="90vh"
          bgSize="cover"
          bgPosition="bottom"
        />
      </Box>
      {/* Title & Desctiption Section */}
      <Box
      {...rest}
        zIndex="2"
        textAlign="center"
        transform="translateY(-250px)"
        position="relative"
        marginBottom="-400px"
        padding="32px 24px"
      >
        <WithAnimation>
          <Heading
            fontFamily="cursive"
            fontWeight="normal"
            fontSize="5xl"
            color="secondaryColorText"
            textShadow="1px 1px 1px #7A7A7A"
          >
            Witness <br />
            the Moment
          </Heading>
        </WithAnimation>
        {/* Live stream section */}
        <Box padding="32px 24px 32px 24px">
          <WithAnimation>
            <Box>
              <AspectRatio maxW="560px" ratio={16 / 9} borderRadius="lg" boxShadow="xl">
                <iframe
                  title={`Live wedding of ${THE_BRIDE}`}
                  src={YOUTUBE_EMBED}
                  allowFullScreen
                  loading="lazy"
                  style={{ borderRadius: '16px', border: `4px solid ${BG_SECONDARY}` }}
                />
              </AspectRatio>
            </Box>
          </WithAnimation>
          <WithAnimation>
            <Text
              textAlign="center"
              fontSize="16px"
              fontFamily="serif"
              color="mainColorText"
              margin="32px 0 16px 0"
            >
              {txtWording.subtitleYoutube[lang]}
            </Text>
          </WithAnimation>
          <Center>
            <Link href={YOUTUBE_LINK} target="_blank">
              <Button
                size="xs"
                fontSize="sm"
                fontWeight="normal"
                colorScheme="blackAlpha"
                variant="solid"
                className="animation-heart"
                color="secondaryColorText"
                bgColor="mainColor"
                marginBottom="32px"
              >
                {txtWording.open[lang]}
              </Button>
            </Link>
          </Center>
        </Box>
      </Box>
      <Box height="270px" marginTop="-580px" />
    </Box>
  );
}

export default React.memo(YoutubeLiveSection);