import React, { useState } from 'react';
import { string } from 'prop-types';
import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Text,
  useToast,
  MenuButton,
  Menu,
  InputGroup,
  Icon,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import useRsvp from '@/usecase/use-rsvp';
import { RSVP_STATUS } from '@constants/api';
import useAddNewGuest from '@/usecase/use-add-new-guest';
import { COUNTRY_CODES } from '@constants/countryCodes';
import { BiChevronDown } from 'react-icons/bi';
import WithAnimation from '@components/Common/WithAnimation';
import Image from '@/components/Common/LazyImage';
import { useGuest } from '@/context/guest/Guest';
import { BG_RSVP } from '@/constants/assets';
import {
  ENABLE_GUEST_PERSONALIZATION,
  ENABLE_PARTNER_MODE,
  RSVP_ENABLE_NAME,
  RSVP_ENABLE_PHONE_NUMBER,
  RSVP_ENABLE_ADDRESS,
  RSVP_ENABLE_EMAIL,
  ENABLE_QR_INVITATION,
} from '@/constants/feature-flags';
import {
  ERROR_TYPE,
  FORM_LABEL_PROPS,
  INPUT_COMMON_PROPS,
  SELECT_COMMON_PROPS,
  TYPE,
} from './types';
import { Title, txtForm, txtTitle } from './locales';
import BorderFrame from '@components/Common/BorderFrame';

import useFormFields from '@hooks/useFormFields';

/**
 * function to render RSVP component
 * @returns {JSX.Element}
 * @author idindrakusuma
 */
function RSVPSection({ lang, ...rest }) {
  const toast = useToast();
  const { guest, onUpdateGuest, isRegistered } = useGuest();

  const { onAddNewGuest } = useAddNewGuest();
  const { onUpdateRsvp } = useRsvp();

  const [isLoading, setIsLoading] = useState(false);
  const [errorType, setErrorType] = useState(ERROR_TYPE);
  const { formFields, createChangeHandler } = useFormFields({
    name: isRegistered ? guest.name : '',
    phone_number: guest.phone_number || '',
    guest_quota: guest.guest_quota,
    total_guest: guest.guest_confirm || guest.total_guest || 1,
    address: guest.address === '-' ? '' : guest.address || '',
    email: guest.email === '-' ? '' : guest.email || '',
    is_attended: guest.status === 'NOT_ATTEND' ? TYPE.NO : TYPE.YES,
  });

  const [selectedCountryCode, setSelectedCountryCode] = useState(guest.country_code || '62');

  const handleCountryCodeChange = (code) => {
    setSelectedCountryCode(code);
  };

  const callToasterError = (desc, title = 'Oops!') => {
    toast({
      title,
      description: desc,
      status: 'error',
      isClosable: true,
    });
  };

  const callToasterSuccess = (desc, title = txtForm.success[lang]) => {
    toast({
      title,
      description: desc,
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };

  /**
   * function to submit to BE with check the form value first
   */

  const onSubmitForm = async () => {
    const { name, address, phone_number, total_guest, is_attended, email } = formFields;
    // reset error state

    setErrorType(ERROR_TYPE);
    // define current error state
    let currentErrorType = ERROR_TYPE;

    // Make sure user already input the value
    /**
     * ERROR CHECKING
     */
    if (RSVP_ENABLE_NAME && !name) {
      currentErrorType = { ...currentErrorType, name: txtForm.required[lang] };
    }

    if (RSVP_ENABLE_PHONE_NUMBER) {
      if (!phone_number) {
        currentErrorType = { ...currentErrorType, phone: txtForm.required[lang] };
      } else if (phone_number.length > 13 || phone_number.length < 5) {
        currentErrorType = { ...currentErrorType, phone: txtForm.invalidPhone[lang] };
      }
    }

    if (RSVP_ENABLE_ADDRESS && !address) {
      currentErrorType = { ...currentErrorType, address: txtForm.required[lang] };
    }

    if (RSVP_ENABLE_EMAIL) {
      if (!email) {
        currentErrorType = { ...currentErrorType, email: txtForm.required[lang] };
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
        currentErrorType = { ...currentErrorType, email: txtForm.invalidEmail[lang] };
      }
    }

    if (
      currentErrorType.address ||
      currentErrorType.email ||
      currentErrorType.name ||
      currentErrorType.phone
    ) {
      setErrorType(currentErrorType);
      return;
    }
    setIsLoading(true);
    const guestPayload = {
      name,
      address: address || '-',
      countryCode: selectedCountryCode,
      phoneNumber: phone_number || '-',
      email: email || '-',
      shift: guest.shift || '-',
      system_info: 'WARNING_Terdapat Perubahan dari User_',
    };

    // Usecase: Group or General Invitation
    // We will `insert` instead of `update` if user doens't have code yet. But,
    // we will throw error if QR Invitation
    if (!guest.code) {
      if (ENABLE_QR_INVITATION) {
        setIsLoading(false);
        callToasterError(txtForm.msgError[lang]);
        return;
      }
      await onAddNewGuest({
        ...guestPayload,
        desc: guest.name || '-',
        guest_quota: guest.guest_quota,
        guest_confirm: total_guest && is_attended === TYPE.YES ? total_guest : 0,

        status: is_attended === TYPE.YES ? RSVP_STATUS.attend : RSVP_STATUS.notAttend,
        onSuccess: (guestCode) => {
          setIsLoading(false);
          callToasterSuccess(txtForm.msgSuccess[lang]);
          onUpdateGuest(guestCode, guestPayload.name);
        },
        onFailed: () => {
          setIsLoading(false);
          callToasterError(txtForm.msgError[lang]);
        },
      });
      return;
    }
    await onUpdateRsvp({
      ...guestPayload,
      desc: guest.desc || '-',
      isAttended: is_attended === TYPE.YES,
      totalGuest: total_guest && is_attended === TYPE.YES ? total_guest : 0,

      onSuccess: () => {
        setIsLoading(false);
        // onResetFormWithKey('phone_number');
        callToasterSuccess(txtForm.msgSuccess[lang]);
      },
      onFailed: () => {
        setIsLoading(false);
        callToasterError(txtForm.msgError[lang]);
      },
    });
  };

  return (
    <Box padding="62px 24px 42px 24px" bgColor="bgPrimary">
      <Box bgColor="mainColor" borderRadius="2xl" borderTopRadius="0" boxShadow="xl" {...rest}>
        <WithAnimation>
          <Image src={BG_RSVP} maxWidth="100%" borderRadius="24px" transform="translateY(-24px)" />
        </WithAnimation>
        <Box height="24px" marginTop="-24px" />
        <Box padding="8px" marginTop="-24px">
          <WithAnimation>
            <Box padding="24px" width="100%" pos={'relative'}>
              {/* Title & Desc Section */}
              <BorderFrame isHeader />
              <BorderFrame />
              <Box textAlign="center" color="secondaryColorText">
                <Text fontSize="4xl" fontFamily="cursive" marginTop="16px">
                  {Title[lang]}
                </Text>
                <Text
                  fontSize="md"
                  fontFamily="serif"
                  margin="16px 0 24px 0"
                  padding="0 12px 0 12px"
                >
                  {txtTitle[lang]}
                </Text>
              </Box>

              {/* Form Sections - Name */}
              {RSVP_ENABLE_NAME && (
                <FormControl margin="8px 0" isInvalid={errorType.name}>
                  <FormLabel {...FORM_LABEL_PROPS}>{txtForm.name[lang]}:</FormLabel>
                  <FormLabel
                    fontSize="md"
                    color="secondaryColorText"
                    margin="8px 0"
                    fontFamily="serif"
                    fontStyle={'italic'}
                  >
                    *) {txtForm.nameHelper[lang]}
                  </FormLabel>
                  <Input
                    {...INPUT_COMMON_PROPS}
                    placeholder="..."
                    value={formFields.name}
                    onChange={createChangeHandler('name')}
                    disabled={isRegistered}
                  />
                  <FormErrorMessage color="whiteAlpha.600" marginTop="4px">
                    {errorType.name}
                  </FormErrorMessage>
                </FormControl>
              )}
              {/* Form Sections - Phone Number */}
              {RSVP_ENABLE_PHONE_NUMBER && (
                <FormControl margin="16px 0" isInvalid={errorType.phone}>
                  <FormLabel {...FORM_LABEL_PROPS}>{txtForm.hp[lang]}:</FormLabel>
                  <InputGroup size="sm">
                    <Menu>
                      <MenuButton
                        {...SELECT_COMMON_PROPS}
                        as={Button}
                        rightIcon={<Icon as={BiChevronDown} />}
                      >
                        {`+${selectedCountryCode}`}
                      </MenuButton>
                      <MenuList maxHeight="200px" overflowY="scroll" zIndex={3}>
                        {COUNTRY_CODES.map((country) => (
                          <MenuItem
                            key={country.dial_code}
                            onClick={() => handleCountryCodeChange(country.dial_code)}
                          >
                            {`${country.name}: +${country.dial_code}`}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                    <Input
                      {...INPUT_COMMON_PROPS}
                      placeholder="..."
                      type="number"
                      value={formFields.phone_number}
                      onChange={createChangeHandler('phone_number')}
                    />
                  </InputGroup>
                  <FormErrorMessage color="whiteAlpha.800" marginTop="4px">
                    {errorType.phone}
                  </FormErrorMessage>
                </FormControl>
              )}
              {/* Form Sections - Address */}
              {RSVP_ENABLE_ADDRESS && (
                <FormControl margin="16px 0" isInvalid={errorType.address}>
                  <FormLabel {...FORM_LABEL_PROPS}>{txtForm.address[lang]}:</FormLabel>
                  <Input
                    {...INPUT_COMMON_PROPS}
                    height="40px"
                    placeholder="..."
                    value={formFields.address}
                    onChange={createChangeHandler('address')}
                  />
                  <FormErrorMessage marginTop="4px">{errorType.address}</FormErrorMessage>
                </FormControl>
              )}
              {RSVP_ENABLE_EMAIL && (
                <FormControl margin="16px 0" isInvalid={errorType.email}>
                  <FormLabel {...FORM_LABEL_PROPS}>Email:</FormLabel>
                  <Input
                    {...INPUT_COMMON_PROPS}
                    height="40px"
                    placeholder="..."
                    value={formFields.email}
                    onChange={createChangeHandler('email')}
                  />
                  <FormErrorMessage marginTop="4px">{errorType.email}</FormErrorMessage>
                </FormControl>
              )}
              {/* Form Sections - Attendance */}
              <FormControl margin="8px 0">
                <FormLabel {...FORM_LABEL_PROPS}>{txtForm.willYoutAttend[lang]}</FormLabel>
                <Select
                  {...SELECT_COMMON_PROPS}
                  value={formFields.is_attended}
                  onChange={createChangeHandler('is_attended')}
                >
                  <option value={TYPE.YES} color="white">
                    {txtForm.willAttend[lang]}
                  </option>
                  <option value={TYPE.NO}>{txtForm.noAttend[lang]}</option>
                </Select>
              </FormControl>
              {/* Form Sections - Partner */}
              {formFields.is_attended === TYPE.YES && ENABLE_PARTNER_MODE && (
                <>
                  <FormControl>
                    <FormLabel {...FORM_LABEL_PROPS}>{txtForm.willYouBringPartner[lang]}</FormLabel>
                    <Select
                      {...SELECT_COMMON_PROPS}
                      value={formFields.total_guest}
                      onChange={createChangeHandler('total_guest')}
                    >
                      {ENABLE_GUEST_PERSONALIZATION ? (
                        <>
                          {guest.guest_quota >= 0 && (
                            <option value={1} style={{ color: 'black' }}>
                              1
                            </option>
                          )}
                          {guest.guest_quota > 1 && (
                            <option value={2} style={{ color: 'black' }}>
                              2
                            </option>
                          )}
                          {guest.guest_quota > 2 && (
                            <option value={3} style={{ color: 'black' }}>
                              3
                            </option>
                          )}
                          {guest.guest_quota > 3 && (
                            <option value={4} style={{ color: 'black' }}>
                              4
                            </option>
                          )}
                          {guest.guest_quota > 4 && (
                            <option value={5} style={{ color: 'black' }}>
                              5
                            </option>
                          )}
                        </>
                      ) : (
                        <>
                          <option value={1} style={{ color: 'black' }}>
                            1
                          </option>
                          <option value={2} style={{ color: 'black' }}>
                            2
                          </option>
                        </>
                      )}
                    </Select>
                    {formFields.is_attended === TYPE.YES && false && (
                      <FormHelperText color="mainColorText" fontSize="10px" fontStyle="italic">
                        *) Berdasarkan kondisi saat ini, satu tamu hanya boleh membawa 1 orang
                        partner atau pasangan dalam satu undangan. Terima kasih atas pengertiannya.
                      </FormHelperText>
                    )}
                  </FormControl>
                </>
              )}
              <Center>
                <Button
                  color="mainColorText"
                  fontWeight="light"
                  isLoading={isLoading}
                  marginTop="24px"
                  size="sm"
                  type="button"
                  bgColor="bgPrimary"
                  onClick={onSubmitForm}
                >
                  {txtForm.submit[lang]}
                </Button>
              </Center>
              <Box pos="relative" mb={'14px'}>
                <BorderFrame isFooter left="-24px" top="-6px" w="calc(100% + 48px)" h="42px" />
              </Box>
            </Box>
          </WithAnimation>
        </Box>
      </Box>
    </Box>
  );
}

RSVPSection.propTypes = {
  lang: string,
};

RSVPSection.defaultProps = {
  lang: 'id',
};

export default React.memo(RSVPSection);
