import React from 'react';
import { Box, Heading, Text, Button, Center, useDisclosure } from '@chakra-ui/react';

import ImgLogo from '@/assets/icons/logo.png';
import Image from '@/components/Common/LazyImage';
import WithAnimation from '@/components/Common/WithAnimation';

import { BG_START_JOURNEY } from '@/constants/assets';
import { DEFAULT_LANGUAGE as lang } from '@/constants/feature-flags';

import { styles } from './styles';
import Content from './Content';
import txtWording from './locales';

function LoveStory({...rest}) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Box bgColor="bgPrimary" >
      {/* Heading */}
      <WithAnimation>
        <Box padding="24px" {...rest}>
          <Heading textAlign="center" color="mainColorText" fontWeight="normal">
            Love is more than a feeling.
          </Heading>
          <Heading textAlign="center" color="mainColorText" fontWeight="normal">
            It is full of action.
          </Heading>
        </Box>
      </WithAnimation>
      {/* Image Cover */}
      <Box padding="16px 32px" paddingBottom="42px">
        <Box css={styles}>
          <WithAnimation>
            <Image src={BG_START_JOURNEY} width="100%" boxShadow="2xl" />
          </WithAnimation>
          <Text
            textAlign="center"
            fontFamily="cursive"
            fontSize="4xl"
            color="secondaryColorText"
            position="absolute"
            bottom="16px"
            zIndex="2"
            textShadow="1px 1px 1px #7A7A7A"
          >
            Start the Journey
          </Text>
        </Box>
        <WithAnimation>
          <Box padding="0 8px">
            <Box bgColor="mainColor" padding="16px" borderRadius="0 0 24px 24px" marginTop="-24px">
              <Box height="24px" />
              <Center>
                <Image src={ImgLogo} width="100px" />
              </Center>
              <WithAnimation>
                <Text padding="8px" fontFamily="serif" color="secondaryColorText" textAlign="center">
                  {txtWording.desc[lang]}
                </Text>
              </WithAnimation>
              <WithAnimation>
                <Center margin="16px 0">
                  <Button
                    bgColor="bgPrimary"
                    color="mainColor"
                    fontFamily="body"
                    size="sm"
                    fontWeight="light"
                    onClick={() => onOpen()}
                  >
                    {txtWording.textButton[lang]}
                  </Button>
                </Center>
              </WithAnimation>
            </Box>
          </Box>
        </WithAnimation>
      </Box>

      <Content onClose={onClose} isOpen={isOpen} />
    </Box>
  );
}

export default LoveStory;
