import React from 'react';
import { Box, Text, Center, Button, useDisclosure } from '@chakra-ui/react';

import WithAnimation from '@/components/Common/WithAnimation';
import Image from '@/components/Common/LazyImage';
import ImgLogo from '@/assets/icons/logo.png';
import WeddingFilter from '@/components/Section/Filter';

import { IMG_COVER_1, IMG_COVER_2 } from '@/constants/assets';
import { ENABLE_WEDDING_FILTER, DEFAULT_LANGUAGE as lang } from '@/constants/feature-flags';

import Content from './Content';
import txtWording from './locales';

function PhotoSection() {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Box bgColor="mainColor" padding="32px">
      <WithAnimation top>
        <Center>
          <Image src={ImgLogo} maxWidth="160px" />
        </Center>
      </WithAnimation>
      {/* Quote Sections */}
      <WithAnimation bottom>
        <Box
          fontFamily="serif"
          textAlign="center"
          color="secondaryColorText"
          fontSize="lg"
          margin="16px 0 24px 0"
        >
          <Text>
            “The best thing to hold onto in life is each other”
          </Text>
          {/* <Text margin="16px">—Elizabeth Young</Text> */}
        </Box>
      </WithAnimation>
      {/* Cover Section */}
      <Box>
        <WithAnimation>
          <Center>
            <Image src={IMG_COVER_1} maxW="90%" borderRadius="16px" margin="16px 0" />
          </Center>
        </WithAnimation>
        <WithAnimation>
          <Center>
            <Image src={IMG_COVER_2} maxW="90%" borderRadius="16px" />
          </Center>
        </WithAnimation>
      </Box>
      {/* Button Section */}
      <WithAnimation>
        <Center>
          <Button
            size="sm"
            fontWeight="light"
            color="mainColorText"
            bgColor="bgPrimary"
            marginTop="16px"
            onClick={() => onOpen()}
          >
            {txtWording.openGallery[lang]}
          </Button>
        </Center>
      </WithAnimation>
      {/* Content gallery */}
      <Content isOpen={isOpen} onClose={onClose} />
      {ENABLE_WEDDING_FILTER && (
        <WeddingFilter />
      )}
    </Box>
  );
}

export default React.memo(PhotoSection);