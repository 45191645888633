import React, { useEffect } from 'react';
import { Box, Heading } from '@chakra-ui/react';

import {
  BOY_NAME,
  BOY_NAME_SHORT,
  BOY_PARENT_NAME,
  GIRL_NAME,
  GIRL_NAME_SHORT,
  GIRL_PARENT_NAME,
  IG_BOY,
  IG_GIRL,
  IS_BOY_FIRST,
} from '@/constants';
import { IMG_GIRL, IMG_MAN } from '@/constants/assets';
import { DEFAULT_LANGUAGE as lang } from '@/constants/feature-flags';

import WithAnimation from '@/components/Common/WithAnimation';
import DetailCard from './DetailCard';
import txtWording from './locales';
import useRsvp from '@/usecase/use-rsvp';

function CoupleInfoV7({ ...rest }) {
  const { onOpenInvitation } = useRsvp();

  useEffect(() => {
    onOpenInvitation();
  }, []);
  return (
    <Box>
      {/* Box Header */}
      <Box bgColor="mainColor" padding="48px 32px 58px 32px" {...rest}>
        <WithAnimation>
          <Heading
            fontFamily="cursive"
            fontWeight="normal"
            textAlign="center"
            color="secondaryColorText"
            fontSize="4xl"
            marginBottom="12px"
          >
            {txtWording.title[lang]}
          </Heading>
        </WithAnimation>
      </Box>
      {/* Box Content */}
      {IS_BOY_FIRST && (
        <Box padding="32px" bgColor="bgPrimary">
          <Box transform="translateY(-70px)">
            <DetailCard
              name={BOY_NAME_SHORT}
              fullName={BOY_NAME}
              parentInfo={BOY_PARENT_NAME}
              parentInfoProps={{ color: 'mainColorText' }}
              instagramId={IG_BOY}
              imgSrc={IMG_MAN}
            />
          </Box>
          <DetailCard
            name={GIRL_NAME_SHORT}
            imgSrc={IMG_GIRL}
            fullName={GIRL_NAME}
            parentInfo={GIRL_PARENT_NAME}
            parentInfoProps={{ color: 'mainColorText' }}
            instagramId={IG_GIRL}
          />
        </Box>
      )}
      {!IS_BOY_FIRST && (
        <Box padding="32px" bgColor="bgPrimary">
          <Box transform="translateY(-70px)">
            <DetailCard
              name={GIRL_NAME_SHORT}
              fullName={GIRL_NAME}
              parentInfo={GIRL_PARENT_NAME}
              parentInfoProps={{ color: 'mainColorText' }}
              instagramId={IG_GIRL}
              imgSrc={IMG_GIRL}
            />
          </Box>
          <DetailCard
            name={BOY_NAME_SHORT}
            imgSrc={IMG_MAN}
            fullName={BOY_NAME}
            parentInfo={BOY_PARENT_NAME}
            parentInfoProps={{ color: 'mainColorText' }}
            instagramId={IG_BOY}
          />
        </Box>
      )}
    </Box>
  );
}

export default CoupleInfoV7;
