import React, { useState } from 'react';
import { func, string } from 'prop-types';

import { Box, Text, Heading, Center, Button, useToast } from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';

import WithAnimation from '@components/Common/WithAnimation';
import copyToClipboard from '@invitato/helpers/dist/copyTextToClipboard';

import {
  ENABLE_SEND_GIFT,
  ENABLE_BANK_TRANSFER,
  ENABLE_GIFT_CONFIRMATION,
} from '@/constants/feature-flags';

import txtWording from './locales';
import ModalBank from './ModalBank';
import ModalAddress from './ModalAddress';
import ModalConfirmation from './ModalConfirmation';
import BorderFrame from '@components/Common/BorderFrame';

const MODAL_STATE = {
  bank: 'bank',
  address: 'address',
  idle: '',
  confirm: 'confirmation',
};

/**
 * Function to render GiftCard component with Modal Info
 * @returns {JSX.Element}
 */
function GiftCardSection({ lang, ...rest }) {
  const [modalState, setModalState] = useState(MODAL_STATE.idle);
  const toast = useToast();

  const onCopyText = async (text, message = '') => {
    const result = await copyToClipboard(text);

    if (result) {
      setModalState(MODAL_STATE.idle);
      toast({
        title: txtWording.success[lang],
        description: message || txtWording.successCopyRekening[lang],
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Oops!',
        description: txtWording.failedCopied[lang],
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const onClose = () => {
    setModalState(MODAL_STATE.idle);
  };

  return (
    <Box bgColor="bgPrimary" {...rest}>
      <Box marginTop="-32px" bgColor="bgPrimary" padding="32px">
        <Box padding="32px" width="100%" borderRadius="16px" pos={'relative'}>
          <BorderFrame isGiftFrame isHeader />
          <BorderFrame isGiftFrame />
          <Center>
            <WithAnimation>
              <Heading size="2xl" color="mainColorText" fontWeight="normal" fontFamily="cursive">
                {txtWording.title[lang]}
              </Heading>
            </WithAnimation>
          </Center>
          <WithAnimation>
            <Text fontFamily="serif" color="mainColorText" align="center" margin="16px 0">
              {txtWording.desc[lang]}
            </Text>
          </WithAnimation>
          {ENABLE_BANK_TRANSFER && (
            <Center marginTop="12px">
              <WithAnimation>
                <Button
                  size="sm"
                  colorScheme="blackAlpha"
                  fontWeight="normal"
                  color="mainColor"
                  variant="outline"
                  onClick={() => setModalState(MODAL_STATE.bank)}
                >
                  Bank Transfer
                </Button>
              </WithAnimation>
            </Center>
          )}
          {/* Send gift button section */}
          {ENABLE_SEND_GIFT && (
            <Center marginTop="12px">
              <WithAnimation>
                <Button
                  size="sm"
                  colorScheme="blackAlpha"
                  fontWeight="normal"
                  color="mainColor"
                  variant="outline"
                  onClick={() => setModalState(MODAL_STATE.address)}
                >
                  {txtWording.sendGift[lang]}
                </Button>
              </WithAnimation>
            </Center>
          )}
          {ENABLE_GIFT_CONFIRMATION && (
            <Center marginTop="12px">
              <WithAnimation>
                <Button
                  size="sm"
                  colorScheme="blackAlpha"
                  variant="outline"
                  leftIcon={<FaCheckCircle style={{ marginRight: '-4px' }} />}
                  fontWeight="normal"
                  color="mainColor"
                  onClick={() => setModalState(MODAL_STATE.confirm)}
                >
                  {txtWording.confirm[lang]}
                </Button>
              </WithAnimation>
            </Center>
          )}
          <Box pos={'relative'}>
            <BorderFrame isGiftFrame isFooter />
          </Box>
        </Box>
      </Box>
      <ModalBank
        lang={lang}
        onClose={onClose}
        visible={modalState === MODAL_STATE.bank}
        onCopyText={onCopyText}
      />
      <ModalAddress
        lang={lang}
        onClose={onClose}
        visible={modalState === MODAL_STATE.address}
        onCopyText={onCopyText}
      />
      <ModalConfirmation isOpen={modalState === MODAL_STATE.confirm} onClose={onClose} />
    </Box>
  );
}

GiftCardSection.propTypes = {
  lang: string,
  onShowConfirmation: func.isRequired,
};

GiftCardSection.defaultProps = {
  lang: 'id',
};

export default React.memo(GiftCardSection);
