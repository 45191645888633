import get from '@invitato/helpers/dist/getLocale';

export default {
  title: get('Acara Pernikahan', 'The Vows Details'),
  akad: get('Pemberkatan', 'Akad Nikah'),
  reception: get('Resepsi Pernikahan', 'Reception'),
  maps: get('Lihat Peta', 'View Location'),
  subtitleAkad: get(
    'Dikarenakan kondisi saat ini, maka Pemberkatan akan dilaksanakan secara terbatas pada:', 
    'Akad Nikah will be held on:'),
  subtitleReception: get(
    'Kami memohon kehadiran Bapak/Ibu/Saudara/i dalam pelaksanaan Resepsi Pernikahan pada:', 
    'We request the presence of Mr/Mrs/Ms in the Reception on:'),
};