import get from '@invitato/helpers/dist/getLocale';

export default {
  menuNotFound: get('Menu tidak ditemukan!', `Section can't be found!`),
  accessCard: get('Kartu Akses', 'Access Card'),
  bride: get('Kedua Mempelai','Bride & Groom'),
  groom: get('Kedua Mempelai','Groom & Bride'),
  covidProtocol: get('Protokol Kesehatan', 'Health Protocol'),
  rsvp: get('Konfirmasi Kehadiran', 'RSVP'),
  gift: get('Tanda Kasih', 'Wedding Gift'),
  details: get('Detail Acara', 'Wedding Details'),
  live: get('Siaran Langsung', 'Live Streaming'),
};