import React from 'react';
import { Box, Heading, Text, Center } from '@chakra-ui/react';

import ImgDivider from '@/assets/border/divider.png';

import ViewLocationButton from '@components/Common/Buttons/ViewLocation';
import WithAnimation from '@components/Common/WithAnimation';
import Image from '@/components/Common/LazyImage';

import generateShift from '@/hooks/useShift';
import useInvitation from '@/hooks/useInvitation';
import { useGuest } from '@/context/guest';

import {
  // WEDDING_RESEPSI_DAY,
  WEDDING_AKAD_TIME,
  // WEDDING_RESEPSI,
  // WEDDING_DATE_DATE,
  WEDDING_LOCATION_ROAD,
  WEDDING_LOCATION,
} from '@/constants';
import { ENABLE_LIVE_STREAMING, DEFAULT_LANGUAGE as lang } from '@/constants/feature-flags';

import txtWording from './locales';
import BorderFrame from '@components/Common/BorderFrame';

/**
 * function to render WeddingSectionV3 component
 * @returns {JSX.Element}
 */
function WeddingSection({ ...rest }) {
  const { guest } = useGuest();
  const { shift } = guest;
  const time = generateShift(shift);
  const invitation = useInvitation();

  return (
    <Box bgColor="mainColor" padding="32px">
      <Box
        {...rest}
        marginTop="32px"
        padding="42px 16px"
        bgRepeat="no-repeat"
        width="100%"
        bgSize="100% 100%"
        // bgImage={`url(${BgBorder})`}
        pos={'relative'}
        marginBottom={!ENABLE_LIVE_STREAMING && !invitation ? '42px' : ''}
      >
        <BorderFrame isWeddingFrame isHeader />
        <BorderFrame isWeddingFrame />
        <Box>
          <WithAnimation>
            <Heading
              textAlign="center"
              fontFamily="cursive"
              color="secondaryColorText"
              fontWeight="normal"
              fontSize="4xl"
            >
              {txtWording.title[lang]}
            </Heading>
          </WithAnimation>
          {/* Box Akad Information */}
          <WithAnimation>
            <Box
              textAlign="center"
              color="secondaryColorText"
              fontFamily="serif"
              textTransform="uppercase"
              fontSize="sm"
            >
              <Text fontWeight="bold" m="16px 0" fontSize="md">
                {txtWording.akad[lang]}
              </Text>
              <Text m="16px 0">{txtWording.subtitleAkad[lang]}</Text>
              <Text fontWeight="bold">Saturday, January 27<sup>th</sup> 2024</Text>
              <Text fontWeight="bold">{WEDDING_AKAD_TIME}</Text>
            </Box>
          </WithAnimation>
          {/* DIVIDER section */}
          {invitation && (
            <WithAnimation>
              <Center>
                <Image src={ImgDivider} maxWidth="100%" margin="24px 0" />
              </Center>
            </WithAnimation>
          )}
          {/* Box Reception Information */}
          {invitation && (
            <WithAnimation>
              <Box
                textAlign="center"
                color="secondaryColorText"
                fontFamily="serif"
                textTransform="uppercase"
                fontSize="sm"
              >
                <Text fontWeight="bold" m="16px 0" marginTop="0" fontSize="md">
                  {txtWording.reception[lang]}
                </Text>
                <Text marginBottom="16px">{txtWording.subtitleReception[lang]}</Text>
                <Text fontWeight="bold">Saturday, January 27<sup>th</sup> 2024</Text>
                <Text fontWeight="bold">{time}</Text>
                <br />
                <Text dangerouslySetInnerHTML={{ __html: WEDDING_LOCATION }} />
                <Text>{WEDDING_LOCATION_ROAD}</Text>
              </Box>
            </WithAnimation>
          )}
          {/* BOX Button View Location */}
          {invitation && (
            <WithAnimation>
              <Center>
                <ViewLocationButton
                  marginTop="16px"
                  size="sm"
                  fontWeight="light"
                  color="mainColor"
                  bgColor="bgPrimary"
                  text={txtWording.maps[lang]}
                />
              </Center>
            </WithAnimation>
          )}
          <Box pos={'relative'}>
            <BorderFrame isWeddingFrame isFooter />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default React.memo(WeddingSection);
