import React from 'react';
import { string, object } from 'prop-types';
import { Box, Center, Heading, Text, Divider } from '@chakra-ui/react';

import Image from '@/components/Common/LazyImage';
import InstagramButton from '@components/Common/InstagramButton';
import WithAnimation from '@/components/Common/WithAnimation';

/**
 * Function to render CoupleDetailCard Info
 * @return {JSX.Element}
 */
function CoupleDetailCard(props) {
  const {
    name,
    fullName,
    parentInfo,
    instagramId,
    imgSrc,
    fullNameProps,
    parentInfoProps,
    instagramIdProps,
  } = props;
  return (
    <Box>
      <WithAnimation>
        <Center>
          {imgSrc && <Image src={imgSrc} maxWidth="80%" minHeight="250px" borderRadius="8px" />}
        </Center>
      </WithAnimation>
      <WithAnimation left>
        <Heading
          fontSize="6xl"
          color="mainColorDarkText"
          fontFamily="cursive"
          fontWeight="normal"
          padding="16px 0"
          textAlign="center"
        >
          {name}
        </Heading>
      </WithAnimation>
      <WithAnimation right>
        <Center marginBottom="16px">
          <Divider height="2px" bgColor="mainColor" width="70px" border="none" borderBottom="none" />
        </Center>
      </WithAnimation>
      <WithAnimation>
        <Text
          fontSize="lg"
          fontFamily="serif"
          textAlign="center"
          color="mainColor"
          {...fullNameProps}
        >
          {fullName}
        </Text>
      </WithAnimation>
      <WithAnimation>
        <Text
          marginTop="16px"
          textAlign="center"
          fontSize="sm"
          fontFamily="serif"
          {...parentInfoProps}
          dangerouslySetInnerHTML={{ __html: parentInfo }}
        />
      </WithAnimation>

      <WithAnimation>
        {instagramId && (
          <Center paddingTop="16px">
            <InstagramButton bgColor="mainColorDarkText" id={instagramId} {...instagramIdProps} onlyIcon />
          </Center>
        )}
      </WithAnimation>
    </Box>
  );
}

CoupleDetailCard.propTypes = {
  fullName: string.isRequired,
  fullNameProps: object,
  imgSrc: string,
  instagramId: string,
  instagramIdProps: object,
  name: string.isRequired,
  parentInfo: string.isRequired,
  parentInfoProps: object,
};

CoupleDetailCard.defaultProps = {
  instagramId: '',
  imgSrc: '',
  instagramIdProps: {},
  fullNameProps: {},
  parentInfoProps: {},
};

export default CoupleDetailCard;
