import React from 'react';
import { Box } from '@chakra-ui/react';
import { bool } from 'prop-types';
import { RSVP_BORDER, WEDDING_BORDER, GIFT_BORDER } from './BorderSvg';

function BorderFrame({ isWeddingFrame, isGiftFrame, isHeader, isFooter, ...rest }) {
    if (isWeddingFrame){
        return (
            <Box
                width={isFooter ? "calc(100% + 32px)" : "100%"} 
                height={isHeader || isFooter ? "42px" : "calc(100% - 84px)"}
                bgSize={"100%"}
                bgRepeat="repeat-y"
                bgImage={`url("data:image/svg+xml;utf8,${encodeURIComponent(WEDDING_BORDER)}")`} 
                bgPos={isHeader ? "top" : isFooter ? "bottom" : "center -24px"} 
                position="absolute" 
                top={isHeader || isFooter ? "0" : "42px"}
                left={isFooter? "-16px" : "0"}
                {...rest}
            />
        )
    }
    if(isGiftFrame){
        return (
            <Box
                width={isFooter ? "calc(100% + 64px)" :  "100%"} 
                height={isHeader || isFooter ? "32px" : "calc(100% - 64px)"}
                bgSize={"100%"}
                bgRepeat="repeat-y"
                bgImage={`url("data:image/svg+xml;utf8,${encodeURIComponent(GIFT_BORDER)}")`} 
                bgPos={isHeader ? "top" : isFooter ? "top" : "center -24px"}
                position="absolute" 
                top={isHeader || isFooter ? "0" : "32px"}
                transform={isFooter ? "rotate(180deg)": ""}
                left={isHeader || !isFooter ? "0" : "-32px"}
                {...rest}
            />
        )
    }
    return (
        <Box
            width={isFooter ? "calc(100% + 32px)" : "100%"} 
            height={isHeader || isFooter ? "40px" : "calc(100% - 84px)"}
            bgSize={"100%"}
            bgRepeat="repeat-y"
            bgImage={`url("data:image/svg+xml;utf8,${encodeURIComponent(RSVP_BORDER)}")`} 
            bgPos={isHeader ? "top" : isFooter ? "top" : "center -24px"} 
            transform={isFooter ? "rotate(180deg)": ""}
            position="absolute" 
            top={isHeader || isFooter ? "0" : "40px"}
            left="0"
            {...rest}
        />
    )
}

BorderFrame.propTypes = {
    isHeader: bool,
    isFooter: bool,
    isWeddingFrame: bool,
    isGiftFrame: bool,
};

BorderFrame.defaultProps = {
    isHeader: false,
    isFooter: false,
    isWeddingFrame: false,
    isGiftFrame: false,
};

export default BorderFrame;
