import get from '@invitato/helpers/dist/getLocale';

export default {
  dear: get('Kepada Bapak/Ibu/Saudara/i,', 'Dear Mr/Mrs/Ms,'),
  guest: get('Keluarga & Teman-teman', 'Family & Friends'),
  and: get('dan', 'and'),
  ayatName: get('[QS. Ar-Rum: 21]', '[QS. Ar-Rum: 21]'),
  ayat: get(
    'Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan diantaramu rasa kasih dan sayang. Sungguh, pada yang demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.',
    'And one of His signs is that He created for you spouses from among yourselves so that you may find comfort in them. And He has placed between you compassion and mercy. Surely in this are signs for people who reflect.'),
  openingAnnuncementGeneral: get(
    'Dengan memohon anugerah dan berkat Tuhan, kami bermaksud mengumumkan kepada Bapak/Ibu/Saudara/i acara pernikahan kami:',
    'Together with joyful hearts and the grace of God, we joyfully announce the upcoming marriage of:'),
  openingAnnuncementMuslim: get(
    `Dengan memohon rahmat dan ridho Allah Subhanahu wa Ta'ala, kami bermaksud mengumumkan kepada Bapak/Ibu/Saudara/i acara pernikahan kami:`,
    `Together with the blessing of Allah Subhanahu wa Ta'ala, we joyfully announce the upcoming marriage of:`),
  openingInvitationGeneral: get(
      'Dengan memohon anugerah dan berkat Tuhan, kami memohon kehadiran Bapak/Ibu/Saudara/i pada acara pernikahan kami:',
      'Together with joyful hearts and the grace of God, we cordially request the honor of your presence at the wedding celebration of:'),
  openingInvitationMuslim: get(
      `Dengan memohon rahmat dan ridho Allah Subhanahu wa Ta'ala, kami memohon kehadiran Bapak/Ibu/Saudara/i pada acara pernikahan kami:`,
      `Together with the blessing of Allah Subhanahu wa Ta'ala, we cordially request the honor of your presence at the wedding celebration of:`),
};