import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

import { useGuest } from '@/context/guest';
import useInvitation from '@/hooks/useInvitation';
import WithAnimation from '@/components/Common/WithAnimation';

import { GIRL_NAME, BOY_NAME, WEDDING_DATE, IS_BOY_FIRST } from '@/constants';
import { DEFAULT_LANGUAGE as lang, MUSLIM_INVITATION } from '@/constants/feature-flags';

import txtWording from './locales';

function WelcomeV4() {
  const { guest } = useGuest();
  const { name } = guest;
  const isInvitation = useInvitation();

  return (
    <Box padding="32px" bgColor="bgPrimary">
      <Box>
        {/* Top Section */}
        <WithAnimation left>
          <Heading
            color="mainColorText"
            textAlign="right"
            fontWeight="normal"
            fontSize="6xl"
            paddingRight="32px"
            marginRight="16px"
          >
            W
          </Heading>
        </WithAnimation>
        <WithAnimation right>
          <Heading
            color="mainColorText"
            textAlign="right"
            fontWeight="normal"
            fontSize="6xl"
            marginTop="-24px"
            marginRight="16px"
          >
            R
          </Heading>
        </WithAnimation>
      </Box>
      {/* Dear Section */}
      <WithAnimation>
        <Box>
          <Text textAlign="center" fontWeight="light" color="mainColorText" fontFamily="serif">
            {txtWording.dear[lang]}
          </Text>
          <Text color="mainColorText" textAlign="center" fontFamily="serif" fontWeight="bold">
            {name ? name : txtWording.guest[lang]}
          </Text>
        </Box>
      </WithAnimation>
      {/* Greetings Text */}
      <Box padding="24px 0">
        <WithAnimation>
          <Text
            textTransform="uppercase"
            color="mainColorText"
            fontFamily="serif"
            fontWeight="bold"
            fontSize="xs"
            textAlign="center"
          >
            {isInvitation ? (
              <>
                {MUSLIM_INVITATION
                  ? txtWording.openingInvitationMuslim[lang]
                  : txtWording.openingInvitationGeneral[lang]}
              </>
            ) : (
              <>
                {MUSLIM_INVITATION
                  ? txtWording.openingAnnuncementMuslim[lang]
                  : txtWording.openingAnnuncementGeneral[lang]}
              </>
            )}
          </Text>
        </WithAnimation>
      </Box>
      {IS_BOY_FIRST && (
        <WithAnimation>
          <Box paddingBottom="24px">
            <Text
              textAlign="center"
              color="mainColorText"
              fontFamily="serif"
              textTransform="uppercase"
              fontSize="xl"
            >
              {BOY_NAME}
            </Text>
            <Text textAlign="center" color="mainColorText" fontFamily="heading" fontSize="2xl">
              {txtWording.and[lang]}
            </Text>
            <Text
              textAlign="center"
              color="mainColorText"
              fontFamily="serif"
              textTransform="uppercase"
              fontSize="xl"
            >
              {GIRL_NAME}
            </Text>
            <Text
              marginTop="16px"
              textAlign="center"
              fontWeight="light"
              color="mainColorText"
              fontFamily="serif"
            >
              {WEDDING_DATE}
            </Text>
          </Box>
        </WithAnimation>
      )}
      {!IS_BOY_FIRST && (
        <WithAnimation>
          <Box paddingBottom="24px">
            <Text
              textAlign="center"
              color="mainColorText"
              fontFamily="serif"
              textTransform="uppercase"
              fontSize="xl"
            >
              {GIRL_NAME}
            </Text>
            <Text textAlign="center" color="mainColorText" fontFamily="heading" fontSize="2xl">
              {txtWording.and[lang]}
            </Text>
            <Text
              textAlign="center"
              color="mainColorText"
              fontFamily="serif"
              textTransform="uppercase"
              fontSize="xl"
            >
              {BOY_NAME}
            </Text>
            <Text
              marginTop="16px"
              textAlign="center"
              fontWeight="light"
              color="mainColorText"
              fontFamily="serif"
            >
              {WEDDING_DATE}
            </Text>
          </Box>
        </WithAnimation>
      )}
      {/* Quotes Section */}
      <WithAnimation>
        <Box>
        <Text
            textAlign="center"
            fontWeight="300"
            color="mainColorText"
            //fontStyle="italic"
            fontSize="sm"
          >
            وَمِنْ ءَايَـٰتِهِۦٓ أَنْ خَلَقَ لَكُم مِّنْ أَنفُسِكُمْ أَزْوَٰجًۭا لِّتَسْكُنُوٓا۟ إِلَيْهَا وَجَعَلَ بَيْنَكُم مَّوَدَّةًۭ وَرَحْمَةً ۚ إِنَّ فِى ذَٰلِكَ لَـَٔايَـٰتٍۢ لِّقَوْمٍۢ يَتَفَكَّرُونَ
          </Text>
          <Text
            textAlign="center"
            fontWeight="300"
            color="mainColorText"
            fontStyle="italic"
            fontSize="sm"
          >
            {txtWording.ayat[lang]}
          </Text>
          <Text textAlign="center" marginTop="16px" color="mainColorText" fontSize="sm">
            {txtWording.ayatName[lang]}
          </Text>
        </Box>
      </WithAnimation>
    </Box>
  );
}

export default React.memo(WelcomeV4);
