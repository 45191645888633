import React from 'react';
import { bool, func } from 'prop-types';

import {
  Text,
  Box,
  Center,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerHeader,
  DrawerCloseButton,
} from '@chakra-ui/react';

import txtWording from './locales';
import { string } from 'prop-types';

function ModalAddress({ onClose, visible, onCopyText, lang }) {
  const address = 'Jl. Jalan No. 123, Kota';
  const userName = 'Groom & Bride';

  const renderBank = () => {
    return (
      <Box padding="16px 0">
        <Text textAlign="center" fontSize="lg" fontFamily="serif" color="mainColor" marginTop="0">
          {userName}
          <br />
        </Text>
        <Text textAlign="center" color="black" fontSize="md" fontFamily="serif">
          {address}
        </Text>
        <Center>
          <Button
            size="xs"
            fontWeight="normal"
            colorScheme="blackAlpha"
            bgColor="mainColor"
            marginTop="16px"
            onClick={() => onCopyText(`${userName} - ${address}`, txtWording.successCopyAddress[lang])}
          >
            {txtWording.modalCopy[lang]}
          </Button>
        </Center>
      </Box>
    );
  };

  return (
    <Drawer size="full" onClose={onClose} isOpen={visible}>
      <DrawerOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <DrawerContent maxWidth="500px">
        <DrawerCloseButton marginTop="12px" />
        <DrawerHeader borderBottomWidth="1px" fontSize="3xl" fontWeight="normal" color="mainColor" fontFamily="cursive">
          {txtWording.sendGift[lang]}
        </DrawerHeader>
        <DrawerBody>
          <Text align="center" fontFamily="serif" fontSize="md" margin="16px 0" color="black">
            {txtWording.modalDescAddress[lang]}
          </Text>
          {renderBank()}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

ModalAddress.propTypes = {
  lang: string,
  visible: bool.isRequired,
  onClose: func.isRequired,
  onCopyText: func.isRequired,
};

ModalAddress.defaultProps = {
  lang: 'id',
};

export default ModalAddress;