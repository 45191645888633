import React from 'react';
import { Box, Heading, Text, Center, useDisclosure } from '@chakra-ui/react';

import ImagesViewer from '@components/Common/ImagesViewer/Lazy';
import WithAnimation from '@components/Common/WithAnimation';
import Image from '@/components/Common/LazyImage';
import ImgCovidEN from '@/images/covid-en.jpg';
import ImgCovidID from '@/images/covid-id.jpg';
import useLang from '@/hooks/useLang';

import { title, txtTitle } from './locales';

function CovidSection({...rest}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const lang = useLang();

  return (
    <Box padding="0 24px 32px 24px" bgColor="bgPrimary" >
      <WithAnimation>
        <Heading
          textAlign="center"
          fontWeight="normal"
          fontSize="4xl"
          color="mainColorText"
          fontFamily="cursive"
          {...rest}
        >
          {title[lang]}
        </Heading>
      </WithAnimation>
      <WithAnimation>
        <Center>
          <Text marginTop="16px" textAlign="center" color="black" fontSize="md" fontFamily="serif">
            {txtTitle[lang]}
          </Text>
        </Center>
      </WithAnimation>
      <Box p={4}>
        <WithAnimation>
          <Box
            boxShadow="lg"
            borderRadius="lg"
            onClick={onOpen}
            border="8px solid"
            borderColor="bgPrimary"
          >
            {lang === 'en'
              ? <Image src={ImgCovidEN} />
              : <Image src={ImgCovidID} />
            }
          </Box>
        </WithAnimation>
      </Box>
      {lang === 'en' && isOpen &&
        <ImagesViewer images={[ImgCovidEN]} onClose={onClose} />
      }
      {lang === 'id' && isOpen &&
        <ImagesViewer images={[ImgCovidID]} onClose={onClose} />
      }
    </Box>
  );
}

export default React.memo(CovidSection);