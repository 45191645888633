// import Img1 from './assets/1.jpg';
// import Img2 from './assets/2.jpg';
// import Img3 from './assets/3.jpg';
// import Img4 from './assets/4.jpg';
// import Img5 from './assets/5.jpg';
// import Img6 from './assets/6.jpg';
// import Img7 from './assets/7.jpg';
// import Img8 from './assets/8.jpg';

export default [
  {
    // img: Img1,
    title: '2018',
    description: `Meet for the first time`,
  },
  {
    // img: Img2,
    title: '2019',
    description: `Getting to know each other`,
  },
  {
    // img: Img3,
    title: '2020',
    description: `Building a stronger connection`,
  },
  {
    // img: Img4,
    title: '2023',
    description: `Engagement`,
  },
  
];
