export const ID_RSVP_SECTION = 'id_rsvp_section-idk';
export const ID_DETAIL_SECTION = 'id_detail_section-idk';
export const ID_COUPLE_SECTION = 'id_couple_section-idk';
export const ID_COVID_SECTION = 'id_covid_section-idk';
export const ID_GIFT_SECTION = 'id_giftcard_section-idk';
export const ID_LOVE_STORY_CARD = 'id_lovestory_section-idk';
export const ID_QRCODE = 'id_qrcode_section-idk';
export const ID_LOVESTORY_SECTION = 'id_lovestory_section-idk';
export const ID_YOUTUBE_SECTION = 'id_youtube_section-idk';
export const ELEMENT_CONTENT_ID = 'invitato-detail-content';
