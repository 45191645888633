import React, { useState, useCallback } from 'react';
import { bool, func, string } from 'prop-types';
import CountingDown from '@/components/Section/CountingDown';
import CoupleInfoSection from '@/components/Section/CoupleInfo';
import CoverSection from '@/components/Section/Cover';
import CovidSection from '@/components/Section/Covid';
import FooterSection from '@/components/Section/Footer';
import GiftCard from '@/components/Section/GiftCard';
import LoveStory from '@/components/Section/LoveStory';
import PhotoGallery from '@/components/Section/PhotoGallery';
import QRCard from '@/components/Common/QRCard/V2';
import RSVPSection from '@/components/Section/RSVP';
import WeddingSection from '@/components/Section/Wedding';
import Welcome from '@/components/Section/Welcome';
import WishesSection from '@/components/Section/Wishes';
import YoutubeLive from '@/components/Section/YoutubeLive';

import DarkmodeNotification from '@/components/Common/DarkModeNavigation';
import FloatingMusic from '@/components/Common/FloatingMusic/Lazy';
import Layout from '@/components/Layout';
import Navigation from '@/components/Common/Navigation';
import WithAnimation from '@/components/Common/WithAnimation';

import useInvitation from '@/hooks/useInvitation';
import useLang from '@/hooks/useLang';
import { GuestProvider } from '@/context/guest';
import * as ID from '@/constants/identifier';
import * as FEATURE from '@/constants/feature-flags';

export default function Home() {
  const [showDetailContent, setShowDetailContent] = useState(false);
  const lang = useLang();
  /**
   * function to show detail invitation
   * @returns {void}
   */
  const handleClickDetail = useCallback(() => {
    setShowDetailContent(true);
  }, []);

  return (
    <GuestProvider>
      <Layout>
        <HomePageContent
          handleClickDetail={handleClickDetail}
          showDetailContent={showDetailContent}
          lang={lang}
        />
      </Layout>
    </GuestProvider>
  );
}

function HomePageContent({ handleClickDetail, showDetailContent, lang }) {
  const invitation = useInvitation();

  /**
   * function to render invitation content
   * @returns {JSX.Element}
   */
  const renderContent = () => {
    if (!showDetailContent) return null;

    return (
      <div id={ID.ELEMENT_CONTENT_ID}>
        <Welcome />
        <CoupleInfoSection name={ID.ID_COUPLE_SECTION} />
        {FEATURE.ENABLE_LOVE_STORY && <LoveStory name={ID.ID_LOVESTORY_SECTION} />}
        <CountingDown />
        <WeddingSection name={ID.ID_DETAIL_SECTION} />
        {FEATURE.ENABLE_QR_INVITATION && invitation && <QRCard lang={lang} name={ID.ID_QRCODE} />}
        {FEATURE.ENABLE_RSVP && invitation && <RSVPSection lang={lang} name={ID.ID_RSVP_SECTION} />}
        {FEATURE.ENABLE_COVID_PROTOCOL && invitation && <CovidSection name={ID.ID_COVID_SECTION} />}
        {FEATURE.ENABLE_LIVE_STREAMING && <YoutubeLive name={ID.ID_YOUTUBE_SECTION} />}
        {FEATURE.ENABLE_GIFT_CARD && <GiftCard lang={lang} name={ID.ID_GIFT_SECTION} />}
        <PhotoGallery />
        <WishesSection />
        <FooterSection />
      </div>
    );
  };
  return (
    <>
      <FloatingMusic shouldShowMusicIcon={showDetailContent} />
      {FEATURE.ENABLE_DARKMODE_NOTIFICATION && <DarkmodeNotification lang={lang} />}
      {showDetailContent && <Navigation />}
      <WithAnimation top collapse when={!showDetailContent}>
        <CoverSection elementTarget={ID.ELEMENT_CONTENT_ID} onSeeDetail={handleClickDetail} />
      </WithAnimation>
      {renderContent()}
    </>
  );
}
HomePageContent.propTypes = {
  handleClickDetail: func,
  showDetailContent: bool,
  lang: string,
};
