import React from 'react';
import { bool, string } from 'prop-types';
import { Box, Center, Text, Link, Divider } from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';
import Image from '@/components/Common/LazyImage';
import useInvitation from '@/hooks/useInvitation';
import Logo from '@/assets/icons/logo.png';

import { THE_BRIDE, SOUND_BY, SOUND_URL, URL_IG_INVITATO } from '@/constants';
import {
  ENABLE_COLLABORATION,
  SELECTED_VENDOR_COLLABORATION,
  DEFAULT_LANGUAGE as lang,
} from '@/constants/feature-flags';
import { BG_FOOTER, LOGO_INVITATO } from '@/constants/assets';

import txtWording from './locales';
import Wave from '@components/Common/Wave';

function CopyRightSection() {
  const invitation = useInvitation();

  return (
    <Box>
      <Box
        bgImage={`url(${BG_FOOTER})`}
        bgSize="cover"
        minHeight="90vh"
        bgPos="bottom center"
      ></Box>
      {/* Title and Desc Section */}
      <Box
        textAlign="center"
        color="secondaryColorText"
        fontFamily="serif"
        transform="translateY(-310px)"
        padding="0 24px"
      >
        {/* Logo Section */}
        <WithAnimation>
          <Center>
            <Image src={Logo} maxWidth="150px" marginTop="24px" />
          </Center>
        </WithAnimation>
        <WithAnimation>
          <Text fontFamily="cursive" fontSize={invitation ? 'xl' : 'xl'} fontWeight="bold">
            {invitation ? (
              <>{txtWording.closingTextInvitation[lang]}</>
            ) : (
              <>{txtWording.closingTextAnnouncement[lang]}</>
            )}
          </Text>
        </WithAnimation>
        <WithAnimation>
          <Text fontFamily="body" fontSize={invitation ? 'xl' : 'xl'} fontWeight="bold">
          #WRitinglovestory
          </Text>
        </WithAnimation>
      </Box>
      {/* Reset transform */}
      <Box height="170px" marginTop="-410px" />
      <Wave transform="translateY(-38px)" />
      <Box bgColor="mainColor" marginTop="-40px">
        <Box padding="32px">
          <Center>
            <Link href={URL_IG_INVITATO} target="_blank">
              <Image src={LOGO_INVITATO} maxWidth="120px" />
            </Link>
          </Center>
          <Text color="white" textAlign="center" fontSize="xs">
            Created with Love by Invitato
          </Text>
          {/* Icon IG & WA Not Used */}
          <WithAnimation>
            <Box>
              {/* Collaboration Partner */}
              {ENABLE_COLLABORATION && (
                <Box margin=" 0 8px">
                  <Center>
                    <Divider
                      margin="14px 2px"
                      maxWidth="30%"
                      height="1px"
                      bgColor="white"
                      color="white"
                      border="none"
                      boxShadow="none"
                      borderBottomWidth="none"
                    />
                  </Center>
                  <Center>
                    <Link
                      href={`https://www.instagram.com/${SELECTED_VENDOR_COLLABORATION.instagram}/`}
                      target="_blank"
                    >
                      <Image
                        src={SELECTED_VENDOR_COLLABORATION.imgUrl}
                        {...SELECTED_VENDOR_COLLABORATION.imgProps}
                      />
                    </Link>
                  </Center>
                  <Center>
                    <Text color="mainColorTextLight" align="center" fontSize="xs">
                      In Collaboration with {SELECTED_VENDOR_COLLABORATION.vendorName}
                    </Text>
                  </Center>
                </Box>
              )}
              {/* Copy Right */}
              <Center marginTop={ENABLE_COLLABORATION ? '12px' : '12px'}>
                <Text textAlign="center" color="white" fontSize="xs">
                  {`© ${new Date().getFullYear()} ${THE_BRIDE}. All Right Reserved`}
                </Text>
              </Center>
              <Center>
                <Link textAlign="center" href={SOUND_URL} color="white" fontSize="xs" isExternal>
                  {`Song by ${SOUND_BY}`}
                </Link>
              </Center>
            </Box>
          </WithAnimation>
        </Box>
      </Box>
    </Box>
  );
}

CopyRightSection.propTypes = {
  fontSize: string,
  withFlatIcon: bool,
  withSong: bool,
};

CopyRightSection.defaultProps = {
  fontSize: 'sm',
  withFlatIcon: true,
  withSong: true,
};

export default React.memo(CopyRightSection);
